import { UserPrivilegeLevels, GroupUserPrivilegeLevels } from 'mgr/lib/utils/Constants'

export const RecurringSeriesType = Object.freeze({
  EDIT: 'EDIT',
  DELETE: 'DELETE',
})

export const RecurringSeriesDayType = Object.freeze({
  TODAY: 'TODAY',
  FOLLOWING_DAYS: 'FOLLOWING_DAYS',
  ALL_DAYS: 'ALL_DAYS',
})

export const BlockTimeTypeOptions = Object.freeze({
  ALL: 'ALL',
  SHIFT: 'SHIFT',
  TIME_RANGE: 'TIME_RANGE',
  START_TIME_ONLY: 'START_TIME_ONLY',
})

export const BlockDateTypeOptions = Object.freeze({
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
})

export const ALL_TABLES = 'ALL_TABLES'

export const USER_PRIVILEGE_LEVEL_NAMES = {
  [UserPrivilegeLevels.SUPERUSER]: 'Superuser',
  [UserPrivilegeLevels.MANAGER]: 'Manager',
  [UserPrivilegeLevels.SUBMANAGER]: 'Submanager',
  [UserPrivilegeLevels.BASIC]: 'Basic',
}
export const GROUP_PRIVILEGE_LEVEL_NAMES = {
  [GroupUserPrivilegeLevels.CORPORATE_DIRECTOR_CEO]: 'Corporate Director / CEO',
  [GroupUserPrivilegeLevels.GENERAL_MANAGER_ADVANCED]: 'General Manager (Advanced)',
  [GroupUserPrivilegeLevels.GENERAL_MANAGER_STANDARD]: 'General Manager (Standard)',
  [GroupUserPrivilegeLevels.MARKETING_MANAGER]: 'Marketing Manager',
  [GroupUserPrivilegeLevels.RESTAURANT_MANAGER]: 'Restaurant Manager',
  [GroupUserPrivilegeLevels.HOST_RESERVATIONIST_ADVANCED]: 'Host/Reservationist (Advanced)',
  [GroupUserPrivilegeLevels.HOST_RESERVATIONIST_STANDARD]: 'Host/Reservationist (Standard)',
  [GroupUserPrivilegeLevels.IT_USER_ADMINISTRATOR]: 'IT User Administrator',
  [GroupUserPrivilegeLevels.PROMOTER]: 'Promoter',
}
