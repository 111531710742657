import _ from 'lodash'
import BlockServices from 'mgr/lib/services/BlockServices'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'

/** Prepare the flyout to view a block * */
const setViewMode = blockId => ({
  type: ActionTypes.VIEW_BLOCK,
  blockId,
})

const setSelectedDate = selectedDate => ({
  type: ActionTypes.SET_SELECTED_DATE,
  selectedDate,
})

/** Fetching block * */
const getBlockBegin = () => ({ type: ActionTypes.LOAD_BLOCK_START })
const getBlockSuccess = (block, venue) => ({
  type: ActionTypes.LOAD_BLOCK_SUCCESS,
  block,
  venue,
})
const getBlockFail = () => ({ type: ActionTypes.LOAD_BLOCK_FAIL })

const tryGetBlock = blockId => (dispatch, getState) => {
  const store = getState()
  dispatch(batchActions([getBlockBegin(), setViewMode(blockId)]))
  const venueId = store.appState.venue.id
  const errHandler = error =>
    dispatch(batchActions([getBlockFail(), SlideoutActions.showNotificationError(`Error getting block details: ${error}`)]))
  return BlockServices.fetchBlock(venueId, blockId, errHandler).then(block => dispatch(getBlockSuccess(block, store.appState.venue)))
}

const tryShowBlock = (blockId, selectedDate) => (dispatch, getState) => {
  if (selectedDate) {
    dispatch(setSelectedDate(selectedDate))
  }
  dispatch(tryGetBlock(blockId)).then(() => {
    const state = getState()
    const { block } = state.viewBlockState
    doShowLoadedBlock(dispatch, getState, block)
  })
}

const doShowLoadedBlock = (dispatch, getState, block) => {
  const state = getState()
  dispatch(setViewMode(block.id))
}

const showLoadedBlock = block => (dispatch, getState) => {
  doShowLoadedBlock(dispatch, getState, block)
}

export { tryShowBlock, showLoadedBlock }
