import _ from 'lodash'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { handleAction } from './actions/Epics'
import * as SlideoutActions from './actions/SlideoutActions'
import * as ViewBlockActions from './actions/ViewBlockActions'
import App from './containers/App'
import rootReducer from './reducers/CombineReducer'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(enableBatching(rootReducer), composeEnhancers(applyMiddleware(thunk)))
// Subscribe before initialize gets called, so that we're guaranteed to process user domain venues
Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(() => store.dispatch(GlobalActions.userDomainVenuesLoaded()))

/*
handle 'epics' - taking actions and dispatching new ones
(assumes lastActions reducer is installed)
*/
let lastConsumedActionIndex = -1
store.subscribe(() => {
  const state = store.getState()
  if (_.isEmpty(state.lastActions)) {
    return
  }
  const actionsToRun = state.lastActions.filter(({ action, actionIndex }) => actionIndex > lastConsumedActionIndex)
  if (_.isEmpty(actionsToRun)) {
    return
  }
  lastConsumedActionIndex = _.last(actionsToRun).actionIndex
  const { dispatch, getState } = store
  for (const { action } of actionsToRun) {
    handleAction(action, dispatch, getState)
  }
})

const render = (Component, _globalInit) => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <Component globalInit={_globalInit} />
      </AppContainer>
    </Provider>,
    document.getElementById('sevenrooms-blocks-slideout')
  )
}

render(App, globalInit)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    render(App, globalInit)
  })
}

export const viewBlock = params => {
  store.dispatch(ViewBlockActions.tryShowBlock(params.blockId, params.selectedDate))
}
svrExport('BlockSlideout', 'viewBlock', viewBlock)

export const addBlock = params => {
  store.dispatch(GlobalActions.initializeAction(window.globalInit))
  store.dispatch(SlideoutActions.enterAddBlock(params))
}
svrExport('BlockSlideout', 'addBlock', addBlock)

export const close = () => {
  store.dispatch(SlideoutActions.closeSlideout())
}
svrExport('BlockSlideout', 'close', close)

export const hasHandlers = () => !!store.getState().slideoutState.registeredHandlers
svrExport('BlockSlideout', 'hasHandlers', close)

export const registerCallbackHandlers = handlers => {
  store.dispatch(SlideoutActions.registerCallbackHandlers(handlers))
}
svrExport('BlockSlideout', 'registerCallbackHandlers', registerCallbackHandlers)
