import _ from 'lodash'
import { makeServiceTimeout } from 'mgr/lib/utils/DebugUtils'
import { orderToTime, getVenueToday } from 'svr/common/TimeUtil'
import { getMessageDestinationString } from '../utils/Constants'
import { srDelete, srGet, srPost, srPut } from '@sevenrooms/core/api'

const fetchBlock = (venueId, blockId, errHandler) =>
  srGet(
    `/manager/${venueId}/blocks/${blockId}`,
    {
      rid: Math.floor(Math.random() * 100 + 1),
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return response
  })

const makeBlockDict = (block, startOfDayHour) => ({
  block_name: block.name,
  is_block_type_location: +true, // always location based
  is_block_type_ps: +false, // not implemented
  is_block_time_all: +(block.blockTimeType === 'ALL'),
  is_block_time_shift: +(block.blockTimeType === 'SHIFT'),
  is_block_time_custom: +(block.blockTimeType === 'TIME_RANGE'),
  is_block_start_time_only: +(block.blockTimeType == 'START_TIME_ONLY'),
  is_block_date_single: +(block.blockDateType === 'DATE'),
  is_block_date_custom: +(block.blockDateType === 'DATE_RANGE'),
  blocks_shift_select: block.shiftPersistentId,
  privileges: [...block.privilegeLevels, ...block.privilegeUserIds],
  expiration: block.expiration,
  seating_areas: block.seatingAreaIds,
  tables: block.tableIds.concat(block.tableComboIds),
  block_type_party_size: 1, // not implemented
  start: orderToTime(startOfDayHour, block.startTime).format('LT'),
  end: orderToTime(startOfDayHour, block.endTime).format('LT'),
  specific_times: _.map(block.startTimeTimes, t => orderToTime(startOfDayHour, t).format('LT')),
  block_dow_0: block.dow[0], // monday in python
  block_dow_1: block.dow[1],
  block_dow_2: block.dow[2],
  block_dow_3: block.dow[3],
  block_dow_4: block.dow[4],
  block_dow_5: block.dow[5],
  block_dow_6: block.dow[6],
  is_indefinite: +block.isIndefinite,
  date: block.startDate.format('MM-DD-YYYY'),
  from_date: block.startDate.format('MM-DD-YYYY'),
  to_date: block.endDate.format('MM-DD-YYYY'),
  edit_type: block.recurringSeriesDayType,
})

const addBlock = (venueId, block, startOfDayHour, errHandler) => {
  const url = `/manager/${venueId}/blocks`
  const blockDict = makeBlockDict(block, startOfDayHour)
  return srPost(url, blockDict, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return response
  })
}

const editBlock = (venueId, block, startOfDayHour, errHandler) => {
  const url = `/manager/${venueId}/blocks/${block.blockId}`
  return srPut(url, makeBlockDict(block, startOfDayHour), {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return response
  })
}

const makeDeleteBlockDict = block => ({
  block_id: block.blockId,
  exclude_date: block.startDate.format('MM-DD-YYYY'),
  start_date: block.startDate.format('MM-DD-YYYY'),
  delete_type: block.recurringSeriesDayType,
})

const deleteBlock = (venue, block, errHandler) => {
  const url = `/manager/${venue.id}/blocks/${block.blockId}`
  return srPost(url, makeDeleteBlockDict(block), {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return response
  })
}

export default {
  fetchBlock,
  addBlock,
  editBlock,
  deleteBlock,
}
