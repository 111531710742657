import _ from 'lodash'
import React, { Component } from 'react'
import { render } from 'react-dom'
import { ThemeProvider } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import DropdownArrowsPicker from './DropdownArrowsPicker'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'

const PrivilegeLevelUserDropDown = ({
  privilegeLevels,
  users,
  selectedValues,
  onChange,
  width,
  height,
  style,
  isLightTheme,
  name,
  groupPrivilegeLevels = [],
  isCreateVenueUserAccountsDisabled = false,
  hasVenueVMSUsers = false,
}) => {
  const choices = _.compact([
    hasVenueVMSUsers
      ? {
          name: 'Group Privilege Levels',
          choices: groupPrivilegeLevels.map(pl => ({
            name: pl.name,
            value: pl.value,
          })),
        }
      : undefined,
    !isCreateVenueUserAccountsDisabled ||
    (isCreateVenueUserAccountsDisabled && !hasVenueVMSUsers) ||
    privilegeLevels.filter(privilegeLevel => selectedValues.includes(privilegeLevel.value)).length > 0
      ? {
          name: hasVenueVMSUsers ? 'Venue Privilege Levels' : 'Privilege Levels',
          choices: privilegeLevels.map(pl => ({
            name: pl.name,
            value: pl.value,
          })),
        }
      : undefined,
    {
      name: 'Users',
      choices: users.map(user => ({
        name: user.name,
        value: user.id,
      })),
    },
  ])
  const onChangeHandler = (values, isAddOrRemove, changedVal) => {
    onChange(values)
  }
  const isMultiSelect = true
  const value = null
  const displayOverride = null
  const values = selectedValues
  return (
    <DropdownArrowsPicker
      key="PRIVILEGE_LEVELS_USERS"
      name={name}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      {...{
        value,
        values,
        choices,
        displayOverride,
        onChangeHandler,
        isLightTheme,
        isMultiSelect,
        height,
      }}
    />
  )
}

PrivilegeLevelUserDropDown.defaultProps = {
  width: 154,
  height: 42,
  onChange: () => {},
  isLightTheme: true,
}

PrivilegeLevelUserDropDown.propTypes = {
  name: React.PropTypes.string.isRequired,
  privilegeLevels: React.PropTypes.array.isRequired,
  users: React.PropTypes.array.isRequired,
  selectedValues: React.PropTypes.array.isRequired,
  onChange: React.PropTypes.func.isRequired,
  width: React.PropTypes.number.isRequired,
  height: React.PropTypes.number.isRequired,
  style: React.PropTypes.object,
  isLightTheme: React.PropTypes.bool.isRequired,
}

export default PrivilegeLevelUserDropDown

export const mountPrivilegeLevelUserDropDown = (domId, venueProps) => {
  render(
    <ThemeProvider theme={Theme}>
      <PrivilegeLevelUserDropDown {...venueProps} />
    </ThemeProvider>,
    document.getElementById(domId)
  )
}
svrExport('Components', 'mountPrivilegeLevelUserDropDown', mountPrivilegeLevelUserDropDown)
