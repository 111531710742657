import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as SlideoutActions from 'mgr/blockslideout/actions/SlideoutActions'
import { RecurringSeriesType } from 'mgr/blockslideout/reducers/Constants'
import RecurringSeriesModal from 'mgr/lib/components/RecurringSeriesModal'

class EditDeleteRecurringSeriesModal extends Component {
  constructor(props) {
    super(props)
  }

  renderEditModal() {
    const { blockName, actions } = this.props
    return (
      <RecurringSeriesModal
        title={`Edit ${blockName} block`}
        description="This block is part of a series. What would you like to do?"
        buttonTodayText="Override just this day"
        buttonFollowingDaysText="Edit following days"
        buttonAllDaysText="Edit all days"
        todayText={`All other days in ${blockName} will remain the same`}
        followingDaysText={`This day and future days in ${blockName} will be edited`}
        allDaysText={`All days in ${blockName} will be edited`}
        todaySubtext=""
        followingDaysSubtext="*Single-day overrides will remain the same"
        allDaysSubtext="*Single-day overrides will remain the same"
        onClickToday={actions.enterEditToday}
        onClickFollowingDays={actions.enterEditFollowingDays}
        onClickAllDays={actions.enterEditAllDays}
        onClose={actions.closeSeriesModal}
      />
    )
  }

  renderDeleteModal() {
    const { blockName, actions } = this.props
    return (
      <RecurringSeriesModal
        title={`Delete ${blockName} block`}
        description="This block is part of a series. What would you like to do?"
        buttonTodayText="Delete just this day"
        buttonFollowingDaysText="Delete following days"
        buttonAllDaysText="Delete all days"
        todayText={`All other days in ${blockName} will remain the same`}
        followingDaysText={`This day and future days in ${blockName} will be edited`}
        allDaysText={`All days in ${blockName} will be edited`}
        todaySubtext=""
        followingDaysSubtext="*Single-day overrides will remain the same"
        allDaysSubtext="*Single-day overrides will remain the same"
        onClickToday={actions.enterDeleteToday}
        onClickFollowingDays={actions.enterDeleteFollowingDays}
        onClickAllDays={actions.enterDeleteAllDays}
        onClose={actions.closeSeriesModal}
      />
    )
  }

  render() {
    const { seriesModalVisible, seriesModalType, isRecurring } = this.props
    if (!seriesModalVisible || !isRecurring) {
      return null
    }
    switch (seriesModalType) {
      case RecurringSeriesType.EDIT:
        return this.renderEditModal()
        break
      case RecurringSeriesType.DELETE:
        return this.renderDeleteModal()
        break
      default:
        break
    }
    return null
  }
}

EditDeleteRecurringSeriesModal.propTypes = {
  blockName: React.PropTypes.string.isRequired,
  seriesModalVisible: React.PropTypes.bool.isRequired,
  seriesModalType: React.PropTypes.string.isRequired,
  isRecurring: React.PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      enterEditToday: SlideoutActions.enterEditToday,
      enterEditFollowingDays: SlideoutActions.enterEditFollowingDays,
      enterEditAllDays: SlideoutActions.enterEditAllDays,
      enterDeleteToday: SlideoutActions.enterDeleteToday,
      enterDeleteFollowingDays: SlideoutActions.enterDeleteFollowingDays,
      enterDeleteAllDays: SlideoutActions.enterDeleteAllDays,
      closeSeriesModal: SlideoutActions.closeSeriesModal,
    },
    dispatch
  ),
})

EditDeleteRecurringSeriesModal = connect(mapStateToProps, mapDispatchToProps)(EditDeleteRecurringSeriesModal)
export default EditDeleteRecurringSeriesModal
