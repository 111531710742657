import _ from 'lodash'
import { combineReducers } from 'redux'
import editBlockReducer from 'mgr/blockslideout/reducers/EditBlockReducer'
import slideoutReducer from 'mgr/blockslideout/reducers/SlideoutReducer'
import viewBlockReducer from 'mgr/blockslideout/reducers/ViewBlockReducer'
import { appStateReducer } from 'mgr/lib/reducers/UtilReducers'

const numActionsToKeep = 10 // Most we would dispatch in one batchActions event
let actionIndex = 0
const lastActionsReducer = (prevLastActions = [], action) => {
  const lastActions = [...prevLastActions, { action, actionIndex }]
  actionIndex++
  const numToDrop = lastActions.length - numActionsToKeep
  return numToDrop > 0 ? _.drop(lastActions, numToDrop) : lastActions
}

const rootReducer = combineReducers({
  lastActions: lastActionsReducer,
  appState: appStateReducer,
  slideoutState: slideoutReducer,
  viewBlockState: viewBlockReducer,
  editBlockState: editBlockReducer,
})

export default rootReducer
