import _ from 'lodash'
import * as ActionTypes from 'mgr/blockslideout/actions/ActionTypes'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { GroupUserPrivilegeLevels, UserPrivilegeLevels } from 'mgr/lib/utils/Constants'
import { getVenueToday, timeListToOrder, timeToOrder } from 'svr/common/TimeUtil'
import { RecurringSeriesType, RecurringSeriesDayType, BlockTimeTypeOptions, BlockDateTypeOptions } from './Constants'

const initialEditBlockState = {
  selectedDate: null, // This is the date the user is on
  blockId: '',
  name: '',
  tableIds: [],
  tableComboIds: [],
  seatingAreaIds: [],
  blockTimeType: BlockTimeTypeOptions.ALL,
  blockDateType: BlockDateTypeOptions.DATE,
  shiftPersistentId: null,
  startTime: 52,
  endTime: 53,
  startTimeTimes: [],
  startDate: moment(),
  endDate: moment(),
  dow: [0, 0, 0, 0, 0, 0, 0], // 0 is monday
  privilegeLevels:
    globalInit.hasVenueVMSUsers === 'True'
      ? [
          GroupUserPrivilegeLevels.CORPORATE_DIRECTOR_CEO,
          GroupUserPrivilegeLevels.GENERAL_MANAGER_ADVANCED,
          GroupUserPrivilegeLevels.GENERAL_MANAGER_STANDARD,
          GroupUserPrivilegeLevels.MARKETING_MANAGER,
          GroupUserPrivilegeLevels.RESTAURANT_MANAGER,
        ]
      : [UserPrivilegeLevels.SUPERUSER, UserPrivilegeLevels.MANAGER],
  privilegeUserIds: [],
  isIndefinite: false,
  expiration: 0, // never
  formErrors: [], // messages
  didDismissBookErrorDisplay: false,

  // user selection from modal screen
  recurringSeriesType: RecurringSeriesType.EDIT,
  recurringSeriesDayType: RecurringSeriesDayType.TODAY,
  isFormSubmitting: false,
  timezone: null,
  startOfDayHour: null,
}

const editBlockReducer = (state = initialEditBlockState, action) => {
  switch (action.type) {
    case GlobalActionTypes.INITIALIZE:
      return {
        ...state,
        selectedDate: action.globalInit.dateSelected,
        timezone: action.globalInit.venueTimezone,
        startOfDayHour: action.globalInit.venueStartOfDayHour,
      }
    case ActionTypes.SAVE_BLOCK_START:
      return {
        ...state,
        isFormSubmitting: true,
      }
    case ActionTypes.SAVE_BLOCK_FAIL:
      return {
        ...state,
        isFormSubmitting: false,
      }
    case ActionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.selectedDate,
      }
    case ActionTypes.ENTER_ADD_BLOCK: {
      const { timezone, startOfDayHour } = state
      const today = getVenueToday(timezone, startOfDayHour)
      let { date } = action
      if (date && date.isBefore(today)) {
        date = today
      }
      return {
        ...state,
        ...initialEditBlockState,
        startDate: date || initialEditBlockState.startDate,
        selectedDate: date || state.selectedDate,
        tableIds: _.compact([action.tableId]),
        startTime: action.startTimeOrder || initialEditBlockState.startTime,
        endTime: action.endTimeOrder || initialEditBlockState.endTime,
        blockTimeType: BlockTimeTypeOptions.TIME_RANGE,
      }
    }
    case ActionTypes.ENTER_CLONE_BLOCK:
      return {
        ...state,
        name: `Copy of ${action.block.name}`,
        blockId: '',
      }
    case ActionTypes.LOAD_BLOCK_START:
      return {
        ...initialEditBlockState,
        selectedDate: state.selectedDate,
      }
    case ActionTypes.LOAD_BLOCK_SUCCESS:
    case ActionTypes.SAVE_BLOCK_SUCCESS: {
      return {
        ...state,
        blockId: action.block.id,
        name: action.block.name,
        tableIds: action.block.table_ids || [],
        tableComboIds: action.block.table_combo_ids || [],
        seatingAreaIds: action.block.seating_area_ids || [],
        blockTimeType: action.block.block_time_type,
        blockDateType: action.block.block_date_type,
        shiftPersistentId: action.block.shift_persistent_id,
        startTime: timeToOrder(action.venue.startOfDayHour, moment.parseZone(action.block.start)),
        endTime: timeToOrder(action.venue.startOfDayHour, moment.parseZone(action.block.end)),
        startTimeTimes: timeListToOrder(action.venue.startOfDayHour, action.block.block_start_times || []),
        startDate: moment(action.block.date),
        endDate: moment(action.block.end_date),
        dow: action.block.days_of_week || initialEditBlockState.dow,
        privilegeLevels: action.block.privilege_levels,
        privilegeUserIds: action.block.privilege_users,
        isIndefinite: action.block.is_indefinite,
        expiration: action.block.expiration || 0, // minutes
        didDismissBookErrorDisplay: false,
        formErrors: [],
        isFormSubmitting: false,
        selectedDate: state.selectedDate,
      }
    }
    case ActionTypes.CHANGE_BLOCK_TIME_TYPE: {
      return {
        ...state,
        blockTimeType: action.value,
      }
    }
    case ActionTypes.CHANGE_BLOCK_DATE_TYPE: {
      return {
        ...state,
        blockDateType: action.value,
      }
    }
    case ActionTypes.CHANGE_BLOCK_NAME: {
      return {
        ...state,
        name: action.value,
      }
    }
    case ActionTypes.CHANGE_TABLE_IDS: {
      return {
        ...state,
        tableIds: action.value,
      }
    }
    case ActionTypes.CHANGE_TABLE_COMBO_IDS: {
      return {
        ...state,
        tableComboIds: action.value,
      }
    }
    case ActionTypes.CHANGE_SEATING_AREA_IDS: {
      return {
        ...state,
        seatingAreaIds: action.value,
      }
    }
    case ActionTypes.CHANGE_SHIFT: {
      return {
        ...state,
        shiftPersistentId: action.value.persistent_id,
      }
    }
    case ActionTypes.CHANGE_START_TIME: {
      return {
        ...state,
        startTime: action.value,
      }
    }
    case ActionTypes.CHANGE_END_TIME: {
      return {
        ...state,
        endTime: action.value,
      }
    }
    case ActionTypes.CHANGE_START_TIME_TIMES: {
      return {
        ...state,
        startTimeTimes: action.value,
      }
    }
    case ActionTypes.CHANGE_START_DATE: {
      return {
        ...state,
        startDate: action.value,
      }
    }
    case ActionTypes.CHANGE_END_DATE: {
      return {
        ...state,
        endDate: action.value,
      }
    }
    case ActionTypes.CHANGE_INDEFINITE: {
      return {
        ...state,
        isIndefinite: action.value,
      }
    }
    case ActionTypes.CHANGE_DOW: {
      const dow = state.dow.slice()
      dow[action.dowIdx] = action.value
      return {
        ...state,
        dow,
      }
    }
    case ActionTypes.CHANGE_PRIVILEGE_LEVELS: {
      return {
        ...state,
        privilegeLevels: action.value,
      }
    }
    case ActionTypes.CHANGE_PRIVILEGE_USER_IDS: {
      return {
        ...state,
        privilegeUserIds: action.value,
      }
    }
    case ActionTypes.CHANGE_EXPIRATION: {
      return {
        ...state,
        expiration: action.value,
      }
    }
    case ActionTypes.BLOCK_FORM_VALIDATED: {
      return {
        ...state,
        formErrors: action.formErrors,
        didDismissBookErrorDisplay: false,
      }
    }
    case ActionTypes.DISMISS_BLOCK_ERROR_DISPLAY: {
      return {
        ...state,
        didDismissBookErrorDisplay: true,
      }
    }
    case ActionTypes.ENTER_EDIT_TODAY:
      const overrideExt = ` Override ${action.date.format('L')}`
      return {
        ...state,
        recurringSeriesType: RecurringSeriesType.EDIT,
        recurringSeriesDayType: RecurringSeriesDayType.TODAY,
        blockDateType: BlockDateTypeOptions.DATE,
        startDate: action.date,
        name: state.name + overrideExt,
      }
    case ActionTypes.ENTER_EDIT_FOLLOWING_DAYS:
      return {
        ...state,
        recurringSeriesType: RecurringSeriesType.EDIT,
        recurringSeriesDayType: RecurringSeriesDayType.FOLLOWING_DAYS,
        blockDateType: BlockDateTypeOptions.DATE_RANGE,
        startDate: action.date,
      }
    case ActionTypes.ENTER_EDIT_ALL_DAYS:
      return {
        ...state,
        recurringSeriesType: RecurringSeriesType.EDIT,
        recurringSeriesDayType: RecurringSeriesDayType.ALL_DAYS,
      }
    case ActionTypes.ENTER_DELETE_TODAY:
      return {
        ...state,
        recurringSeriesType: RecurringSeriesType.DELETE,
        recurringSeriesDayType: RecurringSeriesDayType.TODAY,
        startDate: action.date,
      }
    case ActionTypes.ENTER_DELETE_FOLLOWING_DAYS:
      return {
        ...state,
        recurringSeriesType: RecurringSeriesType.DELETE,
        recurringSeriesDayType: RecurringSeriesDayType.FOLLOWING_DAYS,
        startDate: action.date,
      }
    case ActionTypes.ENTER_DELETE_ALL_DAYS:
      return {
        ...state,
        recurringSeriesType: RecurringSeriesType.DELETE,
        recurringSeriesDayType: RecurringSeriesDayType.ALL_DAYS,
      }
    default:
      return state
  }
}
export default editBlockReducer
