import _ from 'lodash'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as EditBlockActions from 'mgr/blockslideout/actions/EditBlockActions'
import * as SlideoutActions from 'mgr/blockslideout/actions/SlideoutActions'
import {
  RecurringSeriesType,
  RecurringSeriesDayType,
  BlockTimeTypeOptions,
  BlockDateTypeOptions,
  USER_PRIVILEGE_LEVEL_NAMES,
  GROUP_PRIVILEGE_LEVEL_NAMES,
} from 'mgr/blockslideout/reducers/Constants'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import PrivilegeLevelUserDropDown from 'mgr/lib/components/PrivilegeLevelUserDropDown'
import SeatingAreaTableDropDown from 'mgr/lib/components/SeatingAreaTableDropDown'
import ShiftDropDown from 'mgr/lib/components/ShiftDropDown'
import TimeDropDown from 'mgr/lib/components/TimeDropDown'
import RegularCheckbox from 'mgr/lib/forms/RegularCheckbox'
import TextInput, { InputRestrictions, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { UserPrivilegeLevels, GroupUserPrivilegeLevels } from 'mgr/lib/utils/Constants'
import { VmsIcons } from 'svr/common/VmsIcons'

const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`
const Group = styled.div`
  margin-top: 12px;
  ${props => props.theme.clearFix};
`

const HorizontalLine = styled.div`
  border-bottom: 2px solid ${props => props.theme.mediumGrey};
  margin: 10px 0;
`
const Footer = styled.div`
  height: 100px;
`

const allTimeSlots = _.range(0, 96)

const DatePickerWrapper = ({ date, today, locale, bookedDates, onChangeHandler }) => (
  <DatePicker inline selected={date} minDate={today} locale={locale} highlightDates={bookedDates} onChange={onChangeHandler} />
)

DatePickerWrapper.defaultProps = {
  date: moment(),
  onChangeHandler: () => {},
}

DatePickerWrapper.propTypes = {
  onChangeHandler: React.PropTypes.func,
}

const BlockExpirationOptions = Object.freeze([
  { name: 'Never', value: 0 },
  { name: '30 min prior to start of block', value: 1800 },
  { name: '1 hr prior to start of block', value: 3600 },
  { name: '2 hr prior to start of block', value: 7200 },
  { name: '3 hr prior to start of block', value: 10800 },
  { name: '4 hr prior to start of block', value: 14400 },
  { name: '5 hr prior to start of block', value: 18000 },
  { name: '6 hr prior to start of block', value: 21600 },
  { name: '12 hr prior to start of block', value: 43200 },
  { name: '24 hr prior to start of block', value: 86400 },
  { name: '48 hr prior to start of block', value: 172800 },
  { name: '72 hr prior to start of block', value: 259200 },
  { name: '5 days prior to start of block', value: 432000 },
  { name: '7 days prior to start of block', value: 604800 },
  { name: '10 days prior to start of block', value: 864000 },
  { name: '2 weeks prior to start of block', value: 1209600 },
  { name: '3 weeks prior to start of block', value: 1814400 },
  { name: '4 weeks prior to start of block', value: 2419200 },
  { name: '8 weeks prior to start of block', value: 4838400 },
  { name: '12 weeks prior to start of block', value: 7257600 },
])

const BlockTimeTypeChoices = Object.freeze([
  { name: 'All times', value: BlockTimeTypeOptions.ALL },
  { name: 'All times during a shift', value: BlockTimeTypeOptions.SHIFT },
  { name: 'Custom time range', value: BlockTimeTypeOptions.TIME_RANGE },
  { name: 'Start time only', value: BlockTimeTypeOptions.START_TIME_ONLY },
])

class EditBlockForm extends Component {
  constructor(props) {
    super(props)
    this.changeSelectedTables = this.changeSelectedTables.bind(this)
    this.changePrivilegeLevelsUsers = this.changePrivilegeLevelsUsers.bind(this)
    this.changeStartDate = this.changeStartDate.bind(this)
    this.changeEndDate = this.changeEndDate.bind(this)
    this.privilegeLevels = [
      { name: USER_PRIVILEGE_LEVEL_NAMES[UserPrivilegeLevels.SUPERUSER], value: UserPrivilegeLevels.SUPERUSER },
      { name: USER_PRIVILEGE_LEVEL_NAMES[UserPrivilegeLevels.MANAGER], value: UserPrivilegeLevels.MANAGER },
      { name: USER_PRIVILEGE_LEVEL_NAMES[UserPrivilegeLevels.SUBMANAGER], value: UserPrivilegeLevels.SUBMANAGER },
      { name: USER_PRIVILEGE_LEVEL_NAMES[UserPrivilegeLevels.BASIC], value: UserPrivilegeLevels.BASIC },
    ]
    this.groupPrivilegeLevels = [
      {
        name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.CORPORATE_DIRECTOR_CEO],
        value: GroupUserPrivilegeLevels.CORPORATE_DIRECTOR_CEO,
      },
      {
        name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.GENERAL_MANAGER_ADVANCED],
        value: GroupUserPrivilegeLevels.GENERAL_MANAGER_ADVANCED,
      },
      {
        name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.GENERAL_MANAGER_STANDARD],
        value: GroupUserPrivilegeLevels.GENERAL_MANAGER_STANDARD,
      },
      { name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.MARKETING_MANAGER], value: GroupUserPrivilegeLevels.MARKETING_MANAGER },
      {
        name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.RESTAURANT_MANAGER],
        value: GroupUserPrivilegeLevels.RESTAURANT_MANAGER,
      },
      {
        name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.HOST_RESERVATIONIST_ADVANCED],
        value: GroupUserPrivilegeLevels.HOST_RESERVATIONIST_ADVANCED,
      },
      {
        name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.HOST_RESERVATIONIST_STANDARD],
        value: GroupUserPrivilegeLevels.HOST_RESERVATIONIST_STANDARD,
      },
      {
        name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.IT_USER_ADMINISTRATOR],
        value: GroupUserPrivilegeLevels.IT_USER_ADMINISTRATOR,
      },
      { name: GROUP_PRIVILEGE_LEVEL_NAMES[GroupUserPrivilegeLevels.PROMOTER], value: GroupUserPrivilegeLevels.PROMOTER },
    ]
  }

  changeSelectedTables(values) {
    const { actions, seatingAreasById, tableItemsByTableId, tableCombosById } = this.props

    const seatingAreaIds = _.intersection(values, _.keys(seatingAreasById))
    const tableIds = _.intersection(values, _.keys(tableItemsByTableId))
    const tableComboIds = _.intersection(values, _.keys(tableCombosById))

    actions.changeSeatingAreaIds(seatingAreaIds)
    actions.changeTableIds(tableIds)
    actions.changeTableComboIds(tableComboIds)
  }

  changePrivilegeLevelsUsers(values) {
    const { actions, bookedByNamesById } = this.props
    let privilegeLevels = _.intersection(values, _.map(this.privilegeLevels, 'value'))
    privilegeLevels = privilegeLevels.concat(_.intersection(values, _.map(this.groupPrivilegeLevels, 'value')))
    const privilegeUserIds = _.intersection(values, _.keys(bookedByNamesById))
    actions.changePrivilegeLevels(privilegeLevels)
    actions.changePrivilegeUserIds(privilegeUserIds)
  }

  changeStartDate(newDate) {
    const { actions } = this.props
    // eslint-disable-next-line react/no-string-refs
    this.refs.dropdownArrowsPickerStart.getInstance().closeDropdown()
    actions.changeStartDate(newDate)
  }

  changeEndDate(newDate) {
    const { actions } = this.props
    // eslint-disable-next-line react/no-string-refs
    this.refs.dropdownArrowsPickerEnd.getInstance().closeDropdown()
    actions.changeEndDate(newDate)
  }

  changeDow(dowIdx, val) {
    // eslint-disable-next-line no-bitwise
    this.props.actions.changeDow(val | 0, dowIdx)
  }

  render() {
    const {
      actions,
      block,
      appState,
      editBlockState,
      shifts,
      shiftPersistentId,
      bookedByNamesById,
      seatingAreasById,
      tableItemsByTableId,
      tableCombosById,
      startOfDayHour,
      recurringSeriesType,
      recurringSeriesDayType,
      isEditMode,
    } = this.props

    const nameField = (
      <TextInput
        label="NAME THIS BLOCK"
        placeholder="e.g. Manager Block"
        charLimit={200}
        value={editBlockState.name}
        onChange={actions.changeBlockName}
        style={{
          width: '100%',
          float: 'left',
          marginRight: 15,
          marginBottom: 15,
        }}
      />
    )

    const selectedIds = editBlockState.tableIds.concat(editBlockState.seatingAreaIds, editBlockState.tableComboIds) || []

    const allTables = !selectedIds.length
    const tableSeatingAreaSelect = (
      <SeatingAreaTableDropDown
        name="WHERE SHOULD THIS BLOCK OCCUR?"
        allTables={allTables}
        seatingAreasById={seatingAreasById}
        tablesById={tableItemsByTableId}
        tableCombosById={tableCombosById}
        selectedIds={selectedIds}
        onChange={this.changeSelectedTables}
        style={{ width: '100%' }}
      />
    )

    const users = _.values(bookedByNamesById)
    const selectedValues = editBlockState.privilegeLevels.concat(editBlockState.privilegeUserIds) || []
    const privilegeLevelUserSelect = (
      <PrivilegeLevelUserDropDown
        name="WHO CAN OVERRIDE THIS BLOCK?"
        privilegeLevels={this.privilegeLevels}
        groupPrivilegeLevels={this.groupPrivilegeLevels}
        isCreateVenueUserAccountsDisabled={appState.venueGroup.isCreateVenueUserAccountsDisabled}
        hasVenueVMSUsers={appState.venue.hasVenueVMSUsers}
        users={users}
        selectedValues={selectedValues}
        style={{ width: '100%' }}
        onChange={this.changePrivilegeLevelsUsers}
      />
    )

    const blockExpirationDropdown = (
      <DropdownArrowsPicker
        name="WHEN SHOULD THIS BLOCK BE RELEASED?"
        choices={BlockExpirationOptions}
        value={editBlockState.expiration}
        isLightTheme
        onChangeHandler={actions.changeExpiration}
        style={{ width: '100%' }}
      />
    )

    const blockTimeTypeDropdown = (
      <DropdownArrowsPicker
        name="WHAT TIME SHOULD THIS BLOCK OCCUR?"
        choices={BlockTimeTypeChoices}
        value={editBlockState.blockTimeType}
        isLightTheme
        onChangeHandler={actions.changeBlockTimeType}
        style={{ width: '100%' }}
      />
    )

    const shiftDropDown = (
      <ShiftDropDown
        shifts={shifts}
        shiftPersistentId={shiftPersistentId || ''}
        placeholder="Select Shift"
        onChangeShift={actions.changeShift}
        showNavigation={false}
        isLightTheme
        style={{ width: '100%' }}
      />
    )

    const startTimeDropDown = (
      <TimeDropDown
        name="START TIME"
        timeSlot={editBlockState.startTime}
        startOfDayHour={startOfDayHour}
        timeSlots={allTimeSlots}
        onChangeTime={actions.changeStartTime}
        showNavigation={false}
        isLightTheme
        width={244}
      />
    )

    const endTimeDropDown = (
      <TimeDropDown
        name="END TIME"
        timeSlot={editBlockState.endTime}
        startOfDayHour={startOfDayHour}
        timeSlots={allTimeSlots}
        onChangeTime={actions.changeEndTime}
        showNavigation={false}
        isLightTheme
        width={244}
      />
    )

    const startTimesTimeDropDown = (
      <TimeDropDown
        name="START TIMES"
        values={editBlockState.startTimeTimes}
        startOfDayHour={startOfDayHour}
        timeSlots={allTimeSlots}
        onChangeTime={actions.changeStartTimeTimes}
        showNavigation={false}
        isLightTheme
        style={{ width: '100%' }}
        isMultiSelect
      />
    )

    // Time options
    let timeFields = ''
    switch (editBlockState.blockTimeType) {
      case BlockTimeTypeOptions.SHIFT:
        timeFields = <Group>{shiftDropDown}</Group>
        break
      case BlockTimeTypeOptions.TIME_RANGE:
        timeFields = (
          <Group>
            {startTimeDropDown}
            {endTimeDropDown}
          </Group>
        )
        break
      case BlockTimeTypeOptions.START_TIME_ONLY:
        timeFields = <Group>{startTimesTimeDropDown}</Group>
        break
      default:
        break
    }

    const isDayOverride = recurringSeriesDayType === RecurringSeriesDayType.TODAY
    const isDayOverrideOrAddNew = (isDayOverride && isEditMode) || !isEditMode
    const isEditingFollowingDays = recurringSeriesDayType === RecurringSeriesDayType.FOLLOWING_DAYS
    const isRecurringOrAddNew = (block && block.is_recurring) || !isEditMode
    const blockDateTypeOptions = Object.freeze(
      _.compact([
        isDayOverrideOrAddNew ? { name: 'On a single date', value: BlockDateTypeOptions.DATE } : null,
        isRecurringOrAddNew
          ? {
              name: 'Repeat on a custom date range',
              value: BlockDateTypeOptions.DATE_RANGE,
            }
          : null,
      ])
    )
    const blockDateTypeDropdown = (
      <DropdownArrowsPicker
        name="HOW OFTEN SHOULD THIS BLOCK OCCUR?"
        choices={blockDateTypeOptions}
        value={editBlockState.blockDateType}
        isLightTheme
        disabled={isEditingFollowingDays}
        onChangeHandler={actions.changeBlockDateType}
        style={{ width: '100%' }}
      />
    )

    const startDatePicker = (
      <div className="svr-datepicker">
        <DropdownArrowsPicker
          key="START_DATE"
          name="DATE"
          /* eslint-disable-next-line react/no-string-refs */
          ref="dropdownArrowsPickerStart"
          choices={[{ name: editBlockState.startDate.format('LL'), value: 'dateStart' }]}
          value="dateStart"
          style={{
            float: 'left',
            width: 270,
          }}
          disabled={isEditingFollowingDays}
          isLightTheme
          icon={VmsIcons.CalendarLine}
          dropDown={
            <DatePickerWrapper
              date={editBlockState.startDate}
              today={appState.dateToday}
              locale={appState.venue.locale}
              onChangeHandler={this.changeStartDate}
            />
          }
        />
      </div>
    )

    const endDateDisplay = editBlockState.isIndefinite ? '-' : editBlockState.endDate.format('LL')
    const endDatePicker = (
      <div className="svr-datepicker">
        <DropdownArrowsPicker
          key="END_DATE"
          name="END DATE"
          /* eslint-disable-next-line react/no-string-refs */
          ref="dropdownArrowsPickerEnd"
          choices={[{ name: endDateDisplay, value: 'dateEnd' }]}
          value="dateEnd"
          style={{
            float: 'left',
            width: 270,
          }}
          isLightTheme
          icon={VmsIcons.CalendarLine}
          dropDown={
            <DatePickerWrapper
              date={editBlockState.endDate}
              today={appState.dateToday}
              locale={appState.venue.locale}
              onChangeHandler={this.changeEndDate}
            />
          }
          disabled={isEditingFollowingDays || editBlockState.isIndefinite}
        />
      </div>
    )

    const checkStyle = { float: 'left', marginRight: 32 }
    const createCheckBox = (dowStr, idx) => (
      <RegularCheckbox
        style={checkStyle}
        name={dowStr}
        label={dowStr}
        on={!!editBlockState.dow[idx]}
        enabled={!isEditingFollowingDays}
        onChange={this.changeDow.bind(this, idx)}
      />
    )
    const dowCheckboxes = (
      <FieldGroup>
        {createCheckBox('S', 6)}
        {createCheckBox('M', 0)}
        {createCheckBox('T', 1)}
        {createCheckBox('W', 2)}
        {createCheckBox('T', 3)}
        {createCheckBox('F', 4)}
        {createCheckBox('S', 5)}
      </FieldGroup>
    )

    const indefiniteCheckBox = (
      <RegularCheckbox
        name="Indefinite"
        label="Indefinite"
        on={editBlockState.isIndefinite}
        enabled={!isEditingFollowingDays}
        onChange={actions.changeIndefinite}
        style={{ float: 'right', marginRight: 20 }}
      />
    )

    let dateFields = ''
    switch (editBlockState.blockDateType) {
      case BlockDateTypeOptions.DATE:
        dateFields = <Group>{startDatePicker}</Group>
        break
      case BlockDateTypeOptions.DATE_RANGE:
        dateFields = (
          <Group>
            <Group>{dowCheckboxes}</Group>
            {startDatePicker}
            {endDatePicker}
            <Group>{indefiniteCheckBox}</Group>
          </Group>
        )
        break
      default:
        break
    }

    return (
      <div>
        <FieldGroup key="FieldGroup_NameLocation">
          {nameField}
          {tableSeatingAreaSelect}
        </FieldGroup>
        <HorizontalLine />
        <FieldGroup key="FieldGroup_Time">
          {blockTimeTypeDropdown}
          {timeFields}
        </FieldGroup>
        <HorizontalLine />
        {isDayOverride && isEditMode ? (
          ''
        ) : (
          <div>
            <FieldGroup key="FieldGroup_Date">
              {blockDateTypeDropdown}
              {dateFields}
            </FieldGroup>
            <HorizontalLine />
          </div>
        )}
        <FieldGroup key="FieldGroup_Privilege">{privilegeLevelUserSelect}</FieldGroup>
        <FieldGroup key="FieldGroup_Expiration">{blockExpirationDropdown}</FieldGroup>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  block: state.viewBlockState.block,
  appState: state.appState,
  editBlockState: state.editBlockState,
  bookedByNamesById: state.viewBlockState.bookedByNamesById,
  seatingAreasById: state.viewBlockState.floorPlan.seatingAreasById,
  tableItemsByTableId: state.viewBlockState.floorPlan.tableItemsByTableId,
  tableCombosById: state.viewBlockState.floorPlan.tableCombosById,
  shifts: state.viewBlockState.shifts,
  shiftPersistentId: state.editBlockState.shiftPersistentId,
  startOfDayHour: state.appState.venue.startOfDayHour,

  // user selection from modal screen
  recurringSeriesType: state.editBlockState.recurringSeriesType,
  recurringSeriesDayType: state.editBlockState.recurringSeriesDayType,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changeBlockDateType: EditBlockActions.changeBlockDateType,
      changeBlockTimeType: EditBlockActions.changeBlockTimeType,
      changeBlockName: EditBlockActions.changeBlockName,
      changeTableIds: EditBlockActions.changeTableIds,
      changeTableComboIds: EditBlockActions.changeTableComboIds,
      changeSeatingAreaIds: EditBlockActions.changeSeatingAreaIds,
      changeShift: EditBlockActions.changeShift,
      changeStartTime: EditBlockActions.changeStartTime,
      changeEndTime: EditBlockActions.changeEndTime,
      changeStartTimeTimes: EditBlockActions.changeStartTimeTimes,
      changeStartDate: EditBlockActions.changeStartDate,
      changeEndDate: EditBlockActions.changeEndDate,
      changeIndefinite: EditBlockActions.changeIndefinite,
      changeDow: EditBlockActions.changeDow,
      changePrivilegeLevels: EditBlockActions.changePrivilegeLevels,
      changePrivilegeUserIds: EditBlockActions.changePrivilegeUserIds,
      changeExpiration: EditBlockActions.changeExpiration,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditBlockForm)
