import { connect } from 'react-redux'
import * as SlideoutActions from 'mgr/blockslideout/actions/SlideoutActions'
import SlideoutComponent from 'mgr/lib/components/Slideout'

const mapStateToProps = state => ({
  isSlideoutOpen: state.slideoutState.isSlideoutOpen,
  maxWidth: '600px',
  bodyCSS: 'font: 400 12px Roboto, sans-serif !important;',
})

const mapDispatchToProps = dispatch => ({
  onCloseHandler: () => dispatch(SlideoutActions.verifyNoEditAndCloseSlideout()),
})

const BlockSlideout = connect(mapStateToProps, mapDispatchToProps)(SlideoutComponent)

export default BlockSlideout
