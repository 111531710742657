import * as ActionTypes from 'mgr/blockslideout/actions/ActionTypes'
import { RecurringSeriesType } from './Constants'

const initialSlideoutState = {
  isSlideoutOpen: false,
  isViewMode: true,
  blockId: null,
  notificationBannerState: {
    message: '',
    level: 'INFO', // INFO, WARN, ERROR, SUCCESS
    visible: false,
    actionText: null,
    actionType: null,
  },
  registeredHandlers: [],
  seriesModalVisible: false,
  seriesModalType: RecurringSeriesType.EDIT, // 'EDIT', 'DELETE'
}

const slideoutReducer = (state = initialSlideoutState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTER_CALLBACK_HANDLERS:
      return {
        ...state,
        registeredHandlers: [...state.registeredHandlers, action.handlers],
      }
    case ActionTypes.SHOW_DELETE_SERIES_MODAL:
      return {
        ...state,
        seriesModalVisible: true,
        seriesModalType: RecurringSeriesType.DELETE,
      }
    case ActionTypes.SHOW_EDIT_SERIES_MODAL:
      return {
        ...state,
        seriesModalVisible: true,
        seriesModalType: RecurringSeriesType.EDIT,
      }
    case ActionTypes.CLOSE_SERIES_MODAL:
      return {
        ...state,
        seriesModalVisible: false,
      }
    case ActionTypes.VIEW_BLOCK: {
      const { blockId } = action
      return {
        ...state,
        isSlideoutOpen: true,
        blockId,
        isViewMode: true,
      }
    }
    case ActionTypes.ENTER_ADD_BLOCK:
      return {
        ...state,
        isSlideoutOpen: true,
        blockId: null,
        isViewMode: false,
      }
    case ActionTypes.ENTER_EDIT_BLOCK: {
      const block = action
      return {
        ...state,
        isSlideoutOpen: true,
        blockId: block.id,
        isViewMode: false,
      }
    }
    case ActionTypes.ENTER_CLONE_BLOCK: {
      return {
        ...state,
        isSlideoutOpen: true,
        blockId: null,
        isViewMode: false,
      }
    }
    case ActionTypes.ENTER_EDIT_TODAY:
      return {
        ...state,
        seriesModalVisible: false,
      }
    case ActionTypes.ENTER_EDIT_FOLLOWING_DAYS:
      return {
        ...state,
        seriesModalVisible: false,
      }
    case ActionTypes.ENTER_EDIT_ALL_DAYS:
      return {
        ...state,
        seriesModalVisible: false,
      }
    case ActionTypes.ENTER_DELETE_TODAY:
      return {
        ...state,
        seriesModalVisible: false,
      }
    case ActionTypes.ENTER_DELETE_FOLLOWING_DAYS:
      return {
        ...state,
        seriesModalVisible: false,
      }
    case ActionTypes.ENTER_DELETE_ALL_DAYS:
      return {
        ...state,
        seriesModalVisible: false,
      }
    case ActionTypes.IGNORE_UNSAVED_WARNING:
    case ActionTypes.CLOSE_SLIDEOUT:
      return { ...state, isSlideoutOpen: false }
    case ActionTypes.SHOW_NOTIFICATION_BANNER:
      return {
        ...state,
        notificationBannerState: {
          ...action.notificationBannerState,
          visible: true,
        },
      }
    case ActionTypes.CLOSE_NOTIFICATION_BANNER:
    case ActionTypes.CLICK_NOTIFICATION_BANNER_ACTION:
      return {
        ...state,
        notificationBannerState: {
          ...state.notificationBannerState,
          visible: false,
        },
      }
    default:
      return state
  }
}

export default slideoutReducer
