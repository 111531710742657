import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as SlideoutActions from 'mgr/blockslideout/actions/SlideoutActions'
import { ViewBlockRow, ViewFooter, ActionButton, DeleteButton, CloneButton } from 'mgr/blockslideout/components/ViewComponents'
import { BlockTimeTypeOptions, GROUP_PRIVILEGE_LEVEL_NAMES, USER_PRIVILEGE_LEVEL_NAMES } from 'mgr/blockslideout/reducers/Constants'
import { PulsatingDotsBlue } from 'mgr/layout/StyledComponentUtils'
import { Container } from 'svr/lib/styled-bootstrap-grid'
import BlockSlideout from './BlockSlideout'

class ViewBlock extends Component {
  constructor(props) {
    super(props)
    this.onEditClickHandler = this.onEditClickHandler.bind(this)
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this)
    this.onCloneClickHandler = this.onCloneClickHandler.bind(this)
  }

  onEditClickHandler() {
    const { block, actions } = this.props
    if (!block.is_recurring) {
      actions.enterEditBlock(this.props.block)
    } else {
      actions.showEditSeriesModal()
    }
  }

  onDeleteClickHandler() {
    const { block, actions } = this.props
    if (block.is_recurring) {
      actions.showDeleteSeriesModal()
    } else {
      actions.enterDeleteAllDays()
    }
  }

  onCloneClickHandler() {
    const { block, actions } = this.props
    actions.enterCloneBlock(block)
  }

  renderBlockedAreas(block) {
    return block.table_area_selection_display
  }

  renderTimes(block, shifts) {
    let shiftDisplay = ''
    if (block.block_time_type === BlockTimeTypeOptions.SHIFT) {
      const shift = _.find(shifts, { persistent_id: block.shift_persistent_id })
      shiftDisplay = shift ? ` (${shift.name})` : ''
    }
    return block.time_range_display + shiftDisplay
  }

  renderOccurrences(block) {
    if (!block.is_recurring) {
      return block.date
    }
    const endDate = block.is_indefinite ? 'Indefinite' : block.end_date
    const dowMap = {
      0: 'M',
      1: 'T',
      2: 'W',
      3: 'TH',
      4: 'F',
      5: 'SA',
      6: 'SU',
    }
    const dow = _.compact(_.map(block.days_of_week, (val, idx) => (val ? dowMap[idx] : ''))).join(', ')
    return `${dow} (${block.date} - ${endDate})`
  }

  renderOverrides(block) {
    const { bookedByNamesById } = this.props
    const names = block.privilege_levels.map(privilegeLevel => {
      if (privilegeLevel in GROUP_PRIVILEGE_LEVEL_NAMES) {
        return GROUP_PRIVILEGE_LEVEL_NAMES[privilegeLevel]
      } else if (privilegeLevel in USER_PRIVILEGE_LEVEL_NAMES) {
        return USER_PRIVILEGE_LEVEL_NAMES[privilegeLevel]
      }
      return privilegeLevel
    })
    for (const userid of block.privilege_users) {
      if (userid in bookedByNamesById) {
        names.push(bookedByNamesById[userid].name)
      }
    }
    return names.join(', ')
  }

  renderReleaseTime(block) {
    return block.expiration_display
  }

  renderBody(block) {
    if (!block) {
      return <PulsatingDotsBlue />
    }
    return (
      <Container>
        <ViewBlockRow testId="sr-block-tables" title="Blocked Tables / Areas" value={this.renderBlockedAreas(block)} />
        <ViewBlockRow testId="sr-block-time" title="Time" value={this.renderTimes(block, this.props.shifts)} />
        <ViewBlockRow testId="sr-block-occurrence" title="Occurrence" value={this.renderOccurrences(block)} />
        <ViewBlockRow testId="sr-block-override" title="Override By" value={this.renderOverrides(block)} />
        <ViewBlockRow testId="sr-block-released" title="Block Released" value={this.renderReleaseTime(block)} />
      </Container>
    )
  }

  renderFooter(block) {
    if (!block) {
      return <Container />
    }
    return (
      <ViewFooter>
        <ActionButton onClick={this.onEditClickHandler}>Edit</ActionButton>
        <CloneButton id="block-clone-button" onClick={this.onCloneClickHandler}>
          Clone
        </CloneButton>
        <DeleteButton onClick={this.onDeleteClickHandler}>Delete</DeleteButton>
      </ViewFooter>
    )
  }

  render() {
    const { actions, block } = this.props
    let title = `LOADING BLOCK`
    title = block ? block.name : title
    const showBlurBackground = true
    const body = this.renderBody(block)
    const footer = this.renderFooter(block)
    return (
      <BlockSlideout {...{ title, footer, showBlurBackground }}>
        <Container
          style={{
            paddingRight: 0,
            paddingLeft: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
            overflowX: 'hidden',
            backgroundColor: 'white',
          }}
        >
          {body}
        </Container>
      </BlockSlideout>
    )
  }
}

const mapStateToProps = state => ({
  block: state.viewBlockState.block,
  bookedByNamesById: state.viewBlockState.bookedByNamesById,
  shifts: state.viewBlockState.shifts,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showEditSeriesModal: SlideoutActions.showEditSeriesModal,
      showDeleteSeriesModal: SlideoutActions.showDeleteSeriesModal,
      enterEditBlock: SlideoutActions.enterEditBlock,
      enterEditAllDays: SlideoutActions.enterEditAllDays,
      enterDeleteAllDays: SlideoutActions.enterDeleteAllDays,
      enterCloneBlock: SlideoutActions.enterCloneBlock,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewBlock)
