import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import BookedByServices from 'mgr/lib/services/BookedByServices'
import SeatingServices from 'mgr/lib/services/SeatingServices'
import ShiftServices from 'mgr/lib/services/ShiftServices'
import { NotificationLevel } from 'mgr/lib/utils/Constants'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import * as EditBlockActions from './EditBlockActions'

/* GENERAL SLIDEOUT ACTIONS */
export const initialize = globalInit => ({
  type: GlobalActionTypes.INITIALIZE,
  globalInit,
})
export const closeSlideout = message => ({
  type: ActionTypes.CLOSE_SLIDEOUT,
  message,
})
export const verifyNoEditAndCloseSlideout = message => (dispatch, getState) => {
  dispatch(closeSlideout(message))
}

export const showNotificationBanner = notificationBannerState => ({
  type: ActionTypes.SHOW_NOTIFICATION_BANNER,
  notificationBannerState,
})

export const showNotificationError = message =>
  showNotificationBanner({
    message,
    level: NotificationLevel.ERROR,
    visible: true,
  })

export const closeNotificationBanner = () => ({
  type: ActionTypes.CLOSE_NOTIFICATION_BANNER,
})

export const clickNotificationBannerAction = () => ({
  type: ActionTypes.CLICK_NOTIFICATION_BANNER_ACTION,
})

const doEnterEditBlock = params => ({
  type: ActionTypes.ENTER_EDIT_BLOCK,
  ...params,
})

export const enterEditBlock = params => dispatch => {
  dispatch(
    doEnterEditBlock({
      ...params,
    })
  )
}

export const enterCloneBlock = block => ({
  type: ActionTypes.ENTER_CLONE_BLOCK,
  block,
})

export const showEditSeriesModal = () => ({
  type: ActionTypes.SHOW_EDIT_SERIES_MODAL,
})

export const showDeleteSeriesModal = () => ({
  type: ActionTypes.SHOW_DELETE_SERIES_MODAL,
})

export const doEnterEditToday = date => ({
  type: ActionTypes.ENTER_EDIT_TODAY,
  date,
})

export const enterEditToday = () => (dispatch, getState) => {
  const state = getState()
  dispatch(doEnterEditToday(state.editBlockState.selectedDate))
  dispatch(enterEditBlock(state.viewBlockState.block))
}

export const doEnterEditFollowingDays = date => ({
  type: ActionTypes.ENTER_EDIT_FOLLOWING_DAYS,
  date,
})

export const enterEditFollowingDays = () => (dispatch, getState) => {
  const state = getState()
  dispatch(doEnterEditFollowingDays(state.editBlockState.selectedDate))
  dispatch(enterEditBlock(state.viewBlockState.block))
}

export const doEnterEditAllDays = () => ({
  type: ActionTypes.ENTER_EDIT_ALL_DAYS,
})

export const enterEditAllDays = () => (dispatch, getState) => {
  const state = getState()
  dispatch(doEnterEditAllDays())
  dispatch(enterEditBlock(state.viewBlockState.block))
}

export const doEnterDeleteToday = date => ({
  type: ActionTypes.ENTER_DELETE_TODAY,
  date,
})

export const enterDeleteToday = () => (dispatch, getState) => {
  const state = getState()
  const venueId = state.appState.venue.id
  dispatch(doEnterDeleteToday(state.editBlockState.selectedDate))
  const promises = [dispatch(EditBlockActions.tryDeleteBlock())]
  return Promise.all(promises).then(() => dispatch(closeSlideout('Block deleted')))
}

export const doEnterDeleteFollowingDays = date => ({
  type: ActionTypes.ENTER_DELETE_FOLLOWING_DAYS,
  date,
})

export const enterDeleteFollowingDays = () => (dispatch, getState) => {
  const state = getState()
  const venueId = state.appState.venue.id
  dispatch(doEnterDeleteFollowingDays(state.editBlockState.selectedDate))
  const promises = [dispatch(EditBlockActions.tryDeleteBlock())]
  return Promise.all(promises).then(() => dispatch(closeSlideout('Block deleted')))
}

export const doEnterDeleteAllDays = () => ({
  type: ActionTypes.ENTER_DELETE_ALL_DAYS,
})

export const enterDeleteAllDays = () => (dispatch, getState) => {
  const state = getState()
  const venueId = state.appState.venue.id
  dispatch(doEnterDeleteAllDays())
  const promises = [dispatch(EditBlockActions.tryDeleteBlock())]
  return Promise.all(promises).then(() => dispatch(closeSlideout('Block deleted')))
}

export const closeSeriesModal = () => ({
  type: ActionTypes.CLOSE_SERIES_MODAL,
})

export const invokeRegisteredHandlers = (store, handlerName, params) => {
  for (const handlers of store.slideoutState.registeredHandlers) {
    handlers[handlerName] && handlers[handlerName](...params)
  }
}

/* INIT ALL DATA */

export const fetchInitData = () => (dispatch, getState) => {
  const store = getState()
  const venueId = store.appState.venue.id
  const date = store.appState.dateSelected
  const promises = []
  promises.push(dispatch(tryGetBookedByNamesAction(venueId)))
  promises.push(dispatch(tryGetSeatingAreaTablesAction(venueId, date)))
  promises.push(dispatch(tryGetShiftsAction(venueId, date)))
  return Promise.all(promises)
}

/* SHIFTS */

export const getShiftsSuccess = (venue, shifts) => ({ type: ActionTypes.GET_SHIFTS_SUCCESS, venue, shifts })

export const getShiftsFail = () => ({ type: ActionTypes.GET_SHIFTS_FAIL })

export const tryGetShiftsAction = (venueId, date) => (dispatch, getState) =>
  ShiftServices.fetchShifts(venueId, date).then(
    shifts => {
      const latestStore = getState()
      const { venue } = latestStore.appState
      return dispatch(getShiftsSuccess(venue, shifts))
    },
    () => dispatch(getShiftsFail())
  )

/* BOOKED BY NAMES */

const getBookedByNamesBegin = () => ({
  type: ActionTypes.GET_BOOKED_BY_NAMES_START,
})
const getBookedByNamesSuccess = (venueId, bookedByNames) => ({
  type: ActionTypes.GET_BOOKED_BY_NAMES_SUCCESS,
  venueId,
  bookedByNames,
})
const getBookedByNamesFail = () => ({
  type: ActionTypes.GET_BOOKED_BY_NAMES_FAIL,
})

const tryGetBookedByNamesAction = venueId => dispatch => {
  dispatch(getBookedByNamesBegin())
  return BookedByServices.fetchVenueBookedByNames(venueId).then(
    ({ venueId, bookedByNames }) => dispatch(getBookedByNamesSuccess(venueId, bookedByNames)),
    error => dispatch(batchActions([getBookedByNamesFail(), showNotificationError(`Error getting booked by names: ${error}`)]))
  )
}

/* SEATING AREAS AND TABLES */

const gettingSeatingAreaTables = () => ({
  type: ActionTypes.GET_SEATINGAREA_TABLES_START,
})
const getSeatingAreaTablesSuccess = seatingAreaTables => ({
  type: ActionTypes.GET_SEATINGAREA_TABLES_SUCCESS,
  seatingAreaTables,
})
const getSeatingAreaTablesFail = () => ({
  type: ActionTypes.GET_SEATINGAREA_TABLES_FAIL,
})

const tryGetSeatingAreaTablesAction = (venueId, date) => dispatch => {
  dispatch(gettingSeatingAreaTables())
  return SeatingServices.fetchSeatingAreaTables(
    venueId,
    null, // date NOTE: set to null so we dont filter by date layout
    null // shift
  ).then(
    seatingAreaTables => dispatch(getSeatingAreaTablesSuccess(seatingAreaTables)),
    error => dispatch(batchActions([getSeatingAreaTablesFail(), showNotificationError(`Error getting seating areas and tables: ${error}`)]))
  )
}

/* EXPOSED EXTERNALLY */

export const enterAddBlock = params => ({
  type: ActionTypes.ENTER_ADD_BLOCK,
  ...params,
})

export const registerCallbackHandlers = handlers => ({
  type: ActionTypes.REGISTER_CALLBACK_HANDLERS,
  handlers,
})
