import _ from 'lodash'
import * as ActionTypes from '../actions/ActionTypes'

const initialViewBlockState = {
  block: null,
  isLoadingBlock: false,

  // loaded data
  floorPlan: {
    seatingAreaCodesToTables: {},
    tableItems: [],
    seatingAreas: [],
    seatingAreasByTableId: {},
    seatingAreasToTablesBySeatingAreaId: {},
    tableItemsByTableId: {},
    seatingAreasById: {},
    tableCombosById: {},
  },
  bookedByNamesById: {},
  shifts: [],
}

const viewBlockReducer = (state = initialViewBlockState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_BLOCK_START: {
      return {
        ...state,
        block: null,
        isLoadingBlock: true,
      }
    }
    case ActionTypes.SAVE_BLOCK_SUCCESS: {
      return {
        ...state,
        block: action.block,
        isLoadingBlock: false,
      }
    }
    case ActionTypes.LOAD_BLOCK_SUCCESS: {
      return {
        ...state,
        block: action.block,
        isLoadingBlock: false,
      }
    }
    case ActionTypes.GET_BOOKED_BY_NAMES_SUCCESS: {
      const { bookedByNames } = action
      const bookedByNamesById = _.keyBy(bookedByNames, 'id')
      const newState = {
        ...state,
        bookedByNamesById,
      }
      return newState
    }
    case ActionTypes.GET_SHIFTS_SUCCESS: {
      const { shifts } = action
      const newState = {
        ...state,
        shifts,
      }
      return newState
    }
    case ActionTypes.GET_SEATINGAREA_TABLES_SUCCESS: {
      const floorPlan = action.seatingAreaTables
      return { ...state, floorPlan }
    }
    default:
      return state
  }
}
export default viewBlockReducer
