import React from 'react'
import styled from 'styled-components'

const ViewRow = styled.div`
  width: 100%;
  border-bottom: 1px dotted;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const ViewRowHeader = styled.div`
  font-color: #666;
  padding-left: 10px;
`

const ViewRowValue = styled.div`
  font-color: black;
  padding-right: 10px;
`

const ViewBlockRow = ({ title, value, testId }) => (
  <ViewRow data-test={testId}>
    <ViewRowHeader data-test="sr-row-header">{title}</ViewRowHeader>
    <ViewRowValue data-test="sr-row-value">{value}</ViewRowValue>
  </ViewRow>
)

const ViewFooter = styled.div`
  height: 54px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 7px 17px;
  z-index: 10;
  background-color: white;
  flex-flow: row nowrap;
  justify-content: space-between;
`

const ActionButton = styled.button`
  ${props => props.theme.primaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 33%;
  margin-right: 12px;
  hover: {
    background-color: ${props => props.theme.mediumGrey};
  }
`

const CloneButton = styled.button`
  ${props => props.theme.secondaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 31%;
  margin-right: 12px;
  hover: {
    background-color: ${props => props.theme.mediumGrey};
  }
`

const DeleteButton = styled.button`
  ${props => props.theme.secondaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 31%;
  hover: {
    background-color: ${props => props.theme.mediumGrey};
  }
`

export { ViewRow, ViewRowHeader, ViewRowValue, ViewBlockRow, ViewFooter, ActionButton, DeleteButton, CloneButton }
