import _ from 'lodash'
import BlockServices from 'mgr/lib/services/BlockServices'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'
import * as ViewBlockActions from './ViewBlockActions'

export const changeBlockTimeType = value => ({
  type: ActionTypes.CHANGE_BLOCK_TIME_TYPE,
  value,
})

export const changeBlockDateType = value => ({
  type: ActionTypes.CHANGE_BLOCK_DATE_TYPE,
  value,
})

export const changeBlockName = value => ({
  type: ActionTypes.CHANGE_BLOCK_NAME,
  value,
})

export const changeTableIds = value => ({
  type: ActionTypes.CHANGE_TABLE_IDS,
  value,
})

export const changeTableComboIds = value => ({
  type: ActionTypes.CHANGE_TABLE_COMBO_IDS,
  value,
})

export const changeSeatingAreaIds = value => ({
  type: ActionTypes.CHANGE_SEATING_AREA_IDS,
  value,
})

export const changeShift = value => ({
  type: ActionTypes.CHANGE_SHIFT,
  value,
})

export const changeStartTime = value => ({
  type: ActionTypes.CHANGE_START_TIME,
  value,
})

export const changeEndTime = value => ({
  type: ActionTypes.CHANGE_END_TIME,
  value,
})

export const changeStartTimeTimes = value => ({
  type: ActionTypes.CHANGE_START_TIME_TIMES,
  value,
})

export const changeStartDate = value => ({
  type: ActionTypes.CHANGE_START_DATE,
  value,
})

export const changeEndDate = value => ({
  type: ActionTypes.CHANGE_END_DATE,
  value,
})

export const changeIndefinite = value => ({
  type: ActionTypes.CHANGE_INDEFINITE,
  value,
})

export const changeDow = (value, dowIdx) => ({
  type: ActionTypes.CHANGE_DOW,
  value,
  dowIdx,
})

export const changePrivilegeLevels = value => ({
  type: ActionTypes.CHANGE_PRIVILEGE_LEVELS,
  value,
})

export const changePrivilegeUserIds = value => ({
  type: ActionTypes.CHANGE_PRIVILEGE_USER_IDS,
  value,
})

export const changeExpiration = value => ({
  type: ActionTypes.CHANGE_EXPIRATION,
  value,
})

export const blockFormValidated = formErrors => ({
  type: ActionTypes.BLOCK_FORM_VALIDATED,
  formErrors,
})

export const dismissBlockErrorDisplay = () => ({
  type: ActionTypes.DISMISS_BLOCK_ERROR_DISPLAY,
})

/** Saving block * */
const saveBlockBegin = () => ({ type: ActionTypes.SAVE_BLOCK_START })
const saveBlockSuccess = (block, venue) => ({
  type: ActionTypes.SAVE_BLOCK_SUCCESS,
  block,
  venue,
})
const saveBlockFail = () => ({ type: ActionTypes.SAVE_BLOCK_FAIL })

export const trySaveBlock = () => (dispatch, getState) => {
  const store = getState()
  const { editBlockState } = store

  const venueId = store.appState.venue.id
  const { startOfDayHour } = store.appState.venue
  const errHandler = error =>
    dispatch(batchActions([saveBlockFail(), SlideoutActions.showNotificationError(`Error saving block details: ${error}`)]))

  dispatch(saveBlockBegin())
  let serviceFunc = BlockServices.addBlock
  let callbackAction = 'onAdd'
  if (editBlockState.blockId) {
    serviceFunc = BlockServices.editBlock
    callbackAction = 'onEdit'
  }
  return serviceFunc(venueId, editBlockState, startOfDayHour, errHandler)
    .then(block => dispatch(saveBlockSuccess(block, store.appState.venue)))
    .then(block => dispatch(ViewBlockActions.showLoadedBlock(block)))
    .then(() => {
      const store = getState()
      const { viewBlockState } = store
      SlideoutActions.invokeRegisteredHandlers(store, callbackAction, [viewBlockState.block])
    })
}

/** Delete block * */
const deleteBlockBegin = () => ({ type: ActionTypes.DELETE_BLOCK_START })
const deleteBlockSuccess = () => ({
  type: ActionTypes.DELETE_BLOCK_SUCCESS,
})
const deleteBlockFail = () => ({ type: ActionTypes.DELETE_BLOCK_FAIL })

export const tryDeleteBlock = () => (dispatch, getState) => {
  const store = getState()
  const { editBlockState } = store
  const errHandler = error =>
    dispatch(batchActions([deleteBlockFail(), SlideoutActions.showNotificationError(`Error deleting block details: ${error}`)]))
  dispatch(deleteBlockBegin())
  return BlockServices.deleteBlock(store.appState.venue, editBlockState, errHandler)
    .then(() => dispatch(deleteBlockSuccess()))
    .then(() => {
      SlideoutActions.invokeRegisteredHandlers(store, 'onDelete', [editBlockState.blockId])
    })
}
