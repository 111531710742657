import React, { Component } from 'react'
import styled from 'styled-components'
import Modal from 'svr/lib/Modal/Modal'

const Container = styled.div`
  height: 286px;
  width: 500px;
  background: white;
`

const Title = styled.div`
  padding: 10px 15px 10px 15px;
  border-bottom: 1px solid #ccc;
  font-size: 15px;
  text-transform: uppercase;
  color: black;
`

const ContentArea = styled.div`
  padding: 10px 15px;
`

const Section = styled.div`
  margin-bottom: 15px;
`

const Button = styled.div`
  float: left;
  background: rgb(238, 238, 238);
  color: rgb(102, 102, 102);
  padding: 10px 0px;
  width: 120px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
`
const TextArea = styled.div`
  float: left;
  max-width: 335px;
`
const Subtext = styled.div`
  font-size: 11px;
`

const ClearFix = styled.div`
  clear: both;
`

const Footer = styled.div`
  float: right;
  clear: both;
`

const Close = styled.div`
  float: right;
  cursor: pointer;
`

class RecurringSeriesModal extends Component {
  constructor(props) {
    super(props)
  }

  renderDialog() {
    const {
      title,
      description,
      buttonTodayText,
      buttonFollowingDaysText,
      buttonAllDaysText,
      todayText,
      followingDaysText,
      allDaysText,
      todaySubtext,
      followingDaysSubtext,
      allDaysSubtext,
    } = this.props
    return (
      <Container>
        <Title>
          {title}
          <Close onClick={this.props.onClose}>X</Close>
        </Title>
        <ContentArea>
          <Section>{description}</Section>
          <Section>
            <Button onClick={this.props.onClickToday}>{buttonTodayText}</Button>
            <TextArea>
              {todayText}
              {todaySubtext ? <Subtext>{todaySubtext}</Subtext> : null}
            </TextArea>
            <ClearFix />
          </Section>
          <Section>
            <Button onClick={this.props.onClickFollowingDays}>{buttonFollowingDaysText}</Button>
            <TextArea>
              {followingDaysText}
              <Subtext>{followingDaysSubtext}</Subtext>
            </TextArea>
            <ClearFix />
          </Section>
          <Section>
            <Button onClick={this.props.onClickAllDays}>{buttonAllDaysText}</Button>
            <TextArea>
              {allDaysText}
              <Subtext>{allDaysSubtext}</Subtext>
            </TextArea>
            <ClearFix />
          </Section>
        </ContentArea>
        <Footer>
          <Button onClick={this.props.onClose}>Nevermind</Button>
        </Footer>
      </Container>
    )
  }

  render() {
    return (
      <div>
        <Modal zIndex="500" body={this.renderDialog()} backgroundColor="#ccc" />
      </div>
    )
  }
}

RecurringSeriesModal.propTypes = {
  title: React.PropTypes.string.isRequired,
  description: React.PropTypes.string.isRequired,
  buttonTodayText: React.PropTypes.string.isRequired,
  buttonFollowingDaysText: React.PropTypes.string.isRequired,
  buttonAllDaysText: React.PropTypes.string.isRequired,
  todayText: React.PropTypes.string.isRequired,
  followingDaysText: React.PropTypes.string.isRequired,
  allDaysText: React.PropTypes.string.isRequired,
  todaySubtext: React.PropTypes.string.isRequired,
  followingDaysSubtext: React.PropTypes.string.isRequired,
  allDaysSubtext: React.PropTypes.string.isRequired,
  onClickToday: React.PropTypes.func,
  onClickFollowingDays: React.PropTypes.func,
  onClickAllDays: React.PropTypes.func,
  onClose: React.PropTypes.func,
}

export default RecurringSeriesModal
