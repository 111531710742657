import _ from 'lodash'
import React, { Component } from 'react'
import { Beforeunload } from 'react-beforeunload'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as EditBlockActions from 'mgr/blockslideout/actions/EditBlockActions'
import {
  RecurringSeriesType,
  RecurringSeriesDayType,
  BlockTimeTypeOptions,
  BlockDateTypeOptions,
} from 'mgr/blockslideout/reducers/Constants'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import VmsIcons, { StyledVmsIconS } from 'svr/common/VmsIcons'
import MessageBox from '../../../../component-lib/Manager/MessageBox'
import EditBlockForm from '../components/EditBlockForm'
import BlockSlideout from './BlockSlideout'

const BlockFooter = styled.div`
  height: 54px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 7px 17px;
  z-index: 10;
  background-color: ${props => props.theme.mediumGrey};
`

const BlockButton = styled.button`
  ${props => props.theme.primaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 100%;
`

const ErrorDialogWrapper = styled.div`
  position: absolute;
  bottom: 78px;
  left: 18px;
  right: 18px;
  background-color: ${props => props.theme.error};
  box-shadow: 0 2px 4px 0 rgba(72, 72, 76, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  transition: all 100ms ease-in-out;
  opacity: ${props => (props.isVisible ? '1' : '0')};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`

const ErrorIcon = styled(StyledVmsIconS)`
  width: 38px;
  text-align: right;
  color: ${props => props.theme.white};
`

const ErrorBody = styled.ul`
  flex-grow: 5;
  color: ${props => props.theme.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin: 0 15px !important;
  padding: 15px 0 !important;
  > li {
    list-style-position: inside;
  }
`

const ErrorDismiss = styled(StyledVmsIconS)`
  width: 38px;
  padding: 15px 0;
  text-align: left;
  color: ${props => props.theme.white};
  cursor: pointer;
`

class EditBlock extends Component {
  constructor(props) {
    super(props)
    this.onSubmitClickHandler = this.onSubmitClickHandler.bind(this)
    this.handleOnBeforePageUnload = this.handleOnBeforePageUnload.bind(this)
  }

  componentDidMount() {
    const { actions, isEditMode } = this.props
  }

  isEmptyOrValid(valOrAssertion, errorMsg, formErrors) {
    if (!valOrAssertion) {
      formErrors.push(errorMsg)
    }
  }

  isEmptyList(list, errorMsg, formErrors) {
    const isError = false
    if (!list.length) {
      formErrors.push(errorMsg)
    }
  }

  onSubmitClickHandler() {
    const { actions, editBlockState, isFormSubmitting } = this.props
    if (isFormSubmitting) {
      return
    }

    // validate
    const formErrors = []

    this.isEmptyOrValid(editBlockState.name, 'Please provide a name', formErrors)

    if (editBlockState.blockTimeType == BlockTimeTypeOptions.SHIFT) {
      this.isEmptyOrValid(editBlockState.shiftPersistentId, 'Please select a shift', formErrors)
    }

    if (editBlockState.blockTimeType == BlockTimeTypeOptions.TIME_RANGE) {
      this.isEmptyOrValid(
        editBlockState.endTime > editBlockState.startTime,
        'Please select an end time greater than the start time',
        formErrors
      )
    }

    if (editBlockState.blockTimeType == BlockTimeTypeOptions.START_TIME_ONLY) {
      this.isEmptyList(editBlockState.startTimeTimes, 'Please select start times', formErrors)
    }

    if (editBlockState.blockDateType == BlockDateTypeOptions.DATE_RANGE) {
      this.isEmptyOrValid(_.includes(editBlockState.dow, 1), 'Please select one or more days of the week', formErrors)
      if (!editBlockState.isIndefinite) {
        this.isEmptyOrValid(
          editBlockState.endDate.isAfter(editBlockState.startDate),
          'Please select an end date greater than the start date',
          formErrors
        )
      }
    }

    this.isEmptyList(
      editBlockState.privilegeLevels.concat(editBlockState.privilegeUserIds),
      'Please select an override level or user',
      formErrors
    )

    actions.blockFormValidated(formErrors)

    if (_.isEmpty(formErrors)) {
      actions.trySaveBlock()
    }
  }

  renderErrorDialog() {
    const { actions, editBlockState } = this.props
    const formErrorMessages = _.compact(editBlockState.formErrors).sort()
    const isVisible = !editBlockState.didDismissBookErrorDisplay && !_.isEmpty(formErrorMessages)
    return (
      <ErrorDialogWrapper {...{ isVisible }}>
        <ErrorIcon>{VmsIcons.WarningLine}</ErrorIcon>
        <ErrorBody>
          {formErrorMessages.map(e => (
            <li key={e}>
              {e}
              <br />
            </li>
          ))}
        </ErrorBody>
        <ErrorDismiss onClick={actions.dismissBlockErrorDisplay}>{VmsIcons.Close}</ErrorDismiss>
      </ErrorDialogWrapper>
    )
  }

  handleOnBeforePageUnload(e) {
    if (this.props.isDirty) {
      this.props.actions.onBeforePageUnload()
      const { actions } = this.props
      window.setTimeout(() => window.setTimeout(() => actions.dismissUnsavedWarning(), 200), 1)
      return 'You sure about that?'
    }

    return null
  }

  getEditType() {
    const { editBlockState } = this.props
    const startDateFmt = editBlockState.startDate.format('L')
    switch (editBlockState.recurringSeriesDayType) {
      case RecurringSeriesDayType.TODAY:
        return `Override ${startDateFmt}`
      case RecurringSeriesDayType.FOLLOWING_DAYS:
        return 'All Following Days'
      case RecurringSeriesDayType.ALL_DAYS:
        return 'All days'
        break
      default:
        break
    }
    return ''
  }

  render() {
    const { actions, isEditMode, isFormSubmitting } = this.props
    const { validateFieldMaps } = this
    const title = isEditMode ? `Edit Block (${this.getEditType()})` : 'Add Block'
    const showBlurBackground = true

    let unsavedModal = null

    if (this.props.doShowUnsavedWarning) {
      unsavedModal = (
        <MessageBox
          handleActionClick={this.props.actions.ignoreUnsavedWarning}
          handleCloseClick={this.props.actions.dismissUnsavedWarning}
          dialogType={MessageBox.DialogType.WARNING}
          header="Unsaved block"
          actionButtonText="Discard Changes"
          explanation="The block has been edited but not saved"
        />
      )
    }

    const footer = (
      <BlockFooter {...{ actions }}>
        {this.renderErrorDialog()}
        <BlockButton onClick={this.onSubmitClickHandler} disabled={isFormSubmitting}>
          {isFormSubmitting ? <PulsatingDots /> : isEditMode ? 'Save Block' : 'Add Block'}
        </BlockButton>
      </BlockFooter>
    )

    return (
      <div>
        {unsavedModal}
        <Beforeunload onBeforeunload={this.handleOnBeforePageUnload}>
          <BlockSlideout {...{ title, footer, showBlurBackground }}>
            <EditBlockForm isEditMode={isEditMode} />
          </BlockSlideout>
        </Beforeunload>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  editBlockState: state.editBlockState,
  isFormSubmitting: state.editBlockState.isFormSubmitting,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      blockFormValidated: EditBlockActions.blockFormValidated,
      dismissBlockErrorDisplay: EditBlockActions.dismissBlockErrorDisplay,
      trySaveBlock: EditBlockActions.trySaveBlock,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditBlock)
