export const VIEW_BLOCK = 'VIEW_BLOCK'
export const ENTER_ADD_BLOCK = 'ENTER_ADD_BLOCK'
export const ENTER_EDIT_BLOCK = 'ENTER_EDIT_BLOCK'
export const ENTER_CLONE_BLOCK = 'ENTER_CLONE_BLOCK'
export const REGISTER_CALLBACK_HANDLERS = 'REGISTER_CALLBACK_HANDLERS'
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE'

export const CLOSE_SLIDEOUT = 'CLOSE_SLIDEOUT'

export const SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER'
export const CLICK_NOTIFICATION_BANNER_ACTION = 'CLICK_NOTIFICATION_BANNER_ACTION'
export const CLOSE_NOTIFICATION_BANNER = 'CLOSE_NOTIFICATION_BANNER'

export const SHOW_DELETE_SERIES_MODAL = 'SHOW_DELETE_SERIES_MODAL'
export const SHOW_EDIT_SERIES_MODAL = 'SHOW_EDIT_SERIES_MODAL'
export const CLOSE_SERIES_MODAL = 'CLOSE_SERIES_MODAL'

export const ENTER_EDIT_TODAY = 'ENTER_EDIT_TODAY'
export const ENTER_EDIT_FOLLOWING_DAYS = 'ENTER_EDIT_FOLLOWING_DAYS'
export const ENTER_EDIT_ALL_DAYS = 'ENTER_EDIT_ALL_DAYS'

export const ENTER_DELETE_TODAY = 'ENTER_DELETE_TODAY'
export const ENTER_DELETE_FOLLOWING_DAYS = 'ENTER_DELETE_FOLLOWING_DAYS'
export const ENTER_DELETE_ALL_DAYS = 'ENTER_DELETE_ALL_DAYS'

export const LOAD_BLOCK_START = 'LOAD_BLOCK_START'
export const LOAD_BLOCK_SUCCESS = 'LOAD_BLOCK_SUCCESS'
export const LOAD_BLOCK_FAIL = 'LOAD_BLOCK_FAIL'

export const SAVE_BLOCK_START = 'SAVE_BLOCK_START'
export const SAVE_BLOCK_SUCCESS = 'SAVE_BLOCK_SUCCESS'
export const SAVE_BLOCK_FAIL = 'SAVE_BLOCK_FAIL'
export const DELETE_BLOCK_START = 'DELETE_BLOCK_START'
export const DELETE_BLOCK_SUCCESS = 'DELETE_BLOCK_SUCCESS'
export const DELETE_BLOCK_FAIL = 'DELETE_BLOCK_FAIL'

export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS'
export const GET_SHIFTS_FAIL = 'GET_SHIFTS_FAIL'

export const GET_SEATINGAREA_TABLES_START = 'GET_SEATINGAREA_TABLES_START'
export const GET_SEATINGAREA_TABLES_SUCCESS = 'GET_SEATINGAREA_TABLES_SUCCESS'
export const GET_SEATINGAREA_TABLES_FAIL = 'GET_SEATINGAREA_TABLES_FAIL'

export const GET_BOOKED_BY_NAMES_START = 'GET_BOOKED_BY_NAMES_START'
export const GET_BOOKED_BY_NAMES_SUCCESS = 'GET_BOOKED_BY_NAMES_SUCCESS'
export const GET_BOOKED_BY_NAMES_FAIL = 'GET_BOOKED_BY_NAMES_FAIL'

export const CHANGE_BLOCK_TIME_TYPE = 'CHANGE_BLOCK_TIME_TYPE'
export const CHANGE_BLOCK_DATE_TYPE = 'CHANGE_BLOCK_DATE_TYPE'
export const CHANGE_BLOCK_NAME = 'CHANGE_BLOCK_NAME'
export const CHANGE_TABLE_IDS = 'CHANGE_TABLE_IDS'
export const CHANGE_TABLE_COMBO_IDS = 'CHANGE_TABLE_COMBO_IDS'
export const CHANGE_SEATING_AREA_IDS = 'CHANGE_SEATING_AREA_IDS'
export const CHANGE_SHIFT = 'CHANGE_SHIFT'
export const CHANGE_START_TIME = 'CHANGE_START_TIME'
export const CHANGE_END_TIME = 'CHANGE_END_TIME'
export const CHANGE_START_TIME_TIMES = 'CHANGE_START_TIME_TIMES'
export const CHANGE_START_DATE = 'CHANGE_START_DATE'
export const CHANGE_END_DATE = 'CHANGE_END_DATE'
export const CHANGE_INDEFINITE = 'CHANGE_INDEFINITE'
export const CHANGE_DOW = 'CHANGE_DOW'
export const CHANGE_PRIVILEGE_LEVELS = 'CHANGE_PRIVILEGE_LEVELS'
export const CHANGE_PRIVILEGE_USER_IDS = 'CHANGE_PRIVILEGE_USER_IDS'
export const CHANGE_EXPIRATION = 'CHANGE_EXPIRATION'

export const BLOCK_FORM_VALIDATED = 'BLOCK_FORM_VALIDATED'
export const DISMISS_BLOCK_ERROR_DISPLAY = 'DISMISS_BLOCK_ERROR_DISPLAY'

export const IGNORE_UNSAVED_WARNING = 'IGNORE_UNSAVED_WARNING'
