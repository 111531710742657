import moment from 'moment-timezone'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled, { ThemeProvider } from 'styled-components'
import * as SlideoutActions from 'mgr/blockslideout/actions/SlideoutActions'
import EditDeleteRecurringSeriesModal from 'mgr/blockslideout/components/EditDeleteRecurringSeriesModal'
import GlobalNotificationBanner from 'mgr/layout/GlobalNotificationBanner'
import Theme from 'mgr/layout/Theme'
import EditBlock from './EditBlock'
import ViewBlock from './ViewBlock'

/*
import Perf from 'react-addons-perf'
window.Perf = Perf
*/

const ContentContainer = styled.div`
  font: 400 12px Roboto, sans-serif !important;
`

class App extends Component {
  constructor(props) {
    super(props)

    // setup locale for date time rendering
    moment.locale(this.props.globalInit.venueLocale)
    window.moment && window.moment.locale(this.props.globalInit.venueLocale)
  }

  componentWillMount() {
    const { actions, globalInit } = this.props
    actions.initialize(globalInit)
    actions.fetchInitData()
  }

  render() {
    const { actions, globalInit, isViewMode, hasBlock, viewBlock, seriesModalVisible, seriesModalType, notificationBannerState } =
      this.props

    const handleClose = () => {
      actions.closeNotificationBanner()
    }
    const handleAction = () => {
      actions.clickNotificationBannerAction()
    }

    const activeContents = isViewMode ? <ViewBlock /> : <EditBlock isEditMode={hasBlock} />

    const blockName = viewBlock ? viewBlock.name : 'block'
    const isRecurring = viewBlock ? viewBlock.is_recurring : false
    return (
      <ThemeProvider theme={Theme}>
        <ContentContainer>
          <EditDeleteRecurringSeriesModal
            blockName={blockName}
            seriesModalVisible={seriesModalVisible}
            seriesModalType={seriesModalType}
            isRecurring={isRecurring}
          />
          {activeContents}
          <GlobalNotificationBanner {...notificationBannerState} {...{ handleClose, handleAction }} />
        </ContentContainer>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  isViewMode: state.slideoutState.isViewMode,
  hasBlock: state.slideoutState.blockId != null,
  notificationBannerState: state.slideoutState.notificationBannerState,
  seriesModalVisible: state.slideoutState.seriesModalVisible,
  seriesModalType: state.slideoutState.seriesModalType,
  viewBlock: state.viewBlockState.block,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      initialize: SlideoutActions.initialize,
      closeNotificationBanner: SlideoutActions.closeNotificationBanner,
      clickNotificationBannerAction: SlideoutActions.clickNotificationBannerAction,
      fetchInitData: SlideoutActions.fetchInitData,
      enterEditToday: SlideoutActions.enterEditToday,
      enterEditFollowingDays: SlideoutActions.enterEditFollowingDays,
      enterEditAllDays: SlideoutActions.enterEditAllDays,
      enterDeleteToday: SlideoutActions.enterDeleteToday,
      enterDeleteFollowingDays: SlideoutActions.enterDeleteFollowingDays,
      enterDeleteAllDays: SlideoutActions.enterDeleteAllDays,
      closeSeriesModal: SlideoutActions.closeSeriesModal,
    },
    dispatch
  ),
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
